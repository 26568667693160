import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"
import provinces from "../utils/provinces"
import moment from "moment"

import {
  PrimaryButton,
  SecondaryButton,
  LabelStyled,
  Spinner,
  Layout,
  SEO,
  Title,
  SectionCenter,
  CardContainerStyled,
  PolicyDisplay,
} from "../components"
import { searchPolicy, getCurrentPath, setTravelPlan, setTravellers } from "../redux/actions"

const ViewMyPolicy = ({ location }) => {
  const intl = useIntl()
  const [typeTerm, setTypeTerm] = useState({ type: "", term: "" })
  const [loading, setLoading] = useState(null)
  const types = ["policy_number"]
  // can add "email" and "phone_number" later, currently the api returns data in different format
  const dispatch = useDispatch()
  let searchPolicyResult = useSelector(state => state.searchPolicyResult)
  const unableToContinue = () => {
    if (!typeTerm.term) return true
    if (typeTerm.type === "email") {
      return typeTerm.term.match(/\S+@\S+\.\S+/) === null
    }
    return false
  }

  const onSubmitSearch = () => {
    if (!typeTerm.term) return
    // dispatch(searchPolicy(typeTerm.type, typeTerm.term))
    // setTypeTerm({ type: "", term: "" })
    // if (!searchPolicyResult) setLoading(true)
    navigate('/view-my-policy?policyNumber=' + typeTerm.term);
  }

  useEffect(() => {
      // VTCI137319
    if (location.search.toLowerCase().includes('?policynumber')) {
      dispatch(searchPolicy('policy_number', location.search.toLowerCase().replace('?policynumber=', '').toUpperCase()));
    }
  }, [location.search])


  useEffect(() => {
    dispatch(getCurrentPath(location.pathname))
  }, [getCurrentPath, location.pathname])

  useEffect(() => {
    if (searchPolicyResult !== null) {
      setLoading(false)
    }
  }, [searchPolicyResult])

  const renewPolicy = () => {
    dispatch(setTravelPlan({
      travelFrom: searchPolicyResult.policy.application.travel_plan.from,
      travelTo: provinces.find((p) => p.code === searchPolicyResult.policy.application.travel_plan.to).name,
      applicationDate: moment().format("YYYY-MM-DD"),
      purpose: searchPolicyResult.policy.application.travel_plan.purpose
    }));

    searchPolicyResult.policy.application.travelers.forEach((t) => dispatch(setTravellers({
      first_name: t.first_name,
      last_name: t.last_name,
      birth_date: t.birth_date,
      relationship_status: t.relationship_status,
      gender: t.gender,
      is60yrsOrOlder: t.is60yrsOrOlder,
      id: t.relationship_status,
      mq: t.mq
    })));

    setTypeTerm({ type: "", term: "" })
    navigate("/get-quote-details");
  }

  return (
    <Layout>
      <SEO title="View my policy" />
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        <Title
          title={intl.formatMessage({ id: "view.title" })}
          fontColor="var(--clr-primary-300)"
        />
        <SectionCenter>
          <Wrapper>
            <div className="inputWrapper">
              <p>{intl.formatMessage({ id: "view.text" })}</p>
              {types.map(type => (
                <div key={type}>
                  <LabelStyled>
                    {intl.formatMessage({ id: "view.policyNum" })}
                  </LabelStyled>
                  <StyledInput
                    onChange={e => setTypeTerm({ type, term: e.target.value })}
                    disabled={typeTerm.term && typeTerm.type !== type}
                    name={type}
                    textTransform="none"
                    value={typeTerm.type === type ? typeTerm.term || searchPolicyResult?.policy_number : ""}
                  />
                </div>
              ))}
            </div>
            <div className="btnWrapper">
              <PrimaryButton
                textSize="0.875rem"
                textTransform="capitalize"
                maxWidth="none"
                margin="0 4rem 0 0"
                disabled={unableToContinue()}
                onClick={onSubmitSearch}
              >
                {intl.formatMessage({ id: "btn.submit" })}
              </PrimaryButton>
              {searchPolicyResult?.policy_number &&
                <PrimaryButton
                  textSize="0.875rem"
                  textTransform="capitalize"
                  maxWidth="none"
                  margin="0 4rem 0 0"
                  onClick={() => {
                    renewPolicy();
                  }}
                >
                  {intl.formatMessage({ id: "btn.renew" })}
                </PrimaryButton>
              }
              <SecondaryButton
                buttonColor="var(--clr-neutral-400)"
                textSize="0.875rem"
                margin="none"
                onClick={() => {
                  setTypeTerm({ type: "", term: "" })
                  navigate("/")
                }}
              >
                {intl.formatMessage({ id: "btn.goback" })}
              </SecondaryButton>
            </div>
            {loading === true ? (
              <Spinner />
            ) : searchPolicyResult !== "not found" ? (
              loading === false && (
                <PolicyDisplay searchPolicyResult={searchPolicyResult} />
              )
            ) : (
              loading === false && (
                <CardContainerStyled
                  maxWidth="700px"
                  css={css`
                    margin: 3rem auto 0 auto;
                  `}
                >
                  <div className="innerText">
                    {intl.formatMessage({ id: "view.noResultPolicyNum" })}
                  </div>
                </CardContainerStyled>
              )
            )}
          </Wrapper>
        </SectionCenter>
      </section>
    </Layout>
  )
}

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  .btnWrapper {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }

  .inputWrapper {
    display: grid;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    row-gap: 1.5rem;

    p {
      font-size: 1rem;
      letter-spacing: var(--letter-spacing-sm);
      font-weight: bold;
    }
  }

  .innerText {
    text-align: center;
    padding: 1.5rem;
    text-transform: none;
  }
`

const StyledInput = styled.input`
  display: block;
  width: ${props => props.width || "100%"};
  padding: 1rem;
  border: ${props => props.inputBorderThickness || "2px"} solid
    ${props =>
    props.invalid ? "var(--clr-primary-400)" : "var(--clr-primary-300)"};
  border-radius: ${props => props.borderRadius || "var(--border-radius-md)"};
  text-transform: ${props => props.textTransform || "capitalize"};
  letter-spacing: var(--letter-spacing-sm);
  font-family: ${props => props.fontFamily || "var(--ff-tertiary)"};
  font-size: ${props => props.fontSize || "1rem"};
  font-weight: ${props => props.fontWeight || "normal"};
  color: ${props =>
    props.invalid ? "var(--clr-primary-400)" : "var(--clr-primary-300)"};

  &::placeholder {
    color: ${props =>
    props.invalid ? "var(--clr-primary-400)" : "var(--clr-neutral-300)"};
    text-transform: capitalize;
    letter-spacing: ${props =>
    props.placeHoderLS || "var(--letter-spacing-sm)"};
  }

  :focus {
    outline: none;
    border: ${props =>
    props.focusedInputBorder || "2px solid var(--clr-primary-100)"};
    color: ${props => props.focusedInputColor || "var(--clr-primary-100)"};
  }

  @media (max-width: 799px) {
    min-width: ${props => props.minWidth};
  }
`

export default ViewMyPolicy
